import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AcessoInterceptor } from './components/pages/login/services/acesso.interceptor';
import { provideToastr } from 'ngx-toastr';

/* export const AcessoInterceptor: HttpInterceptorFn = (req: HttpRequest<any>, next: HttpHandlerFn) =>{

  const token = window.localStorage.getItem('access_token');
  const headers = req.clone({
    setHeaders: {
      'Authorization' : `Bearer ${token}`
    }
  })

  return next(headers);
} */

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter([
        {
            path: '',
            loadChildren: () => import('./components/pages/pages.routes')
                .then(r => r.PAGES_ROUTES)
        },

    ]),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    provideToastr(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AcessoInterceptor,
      multi: true
    }
]
};
