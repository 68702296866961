import { Injectable } from '@angular/core';
import { TypeUser } from '../models/typeUser.model';

@Injectable({
  providedIn: 'root'
})
export class TypeUserServiceService {
  public dataUser: any;

  constructor() {
    this.loadUserData();
  }

  // Função para carregar os dados do usuário do localStorage
  public loadUserData(): void {
    const userData = window.localStorage.getItem('data_user');
    if (userData) {
      this.dataUser = JSON.parse(userData);
    }
  }

  // Retorna o tipo de usuário atual
  getUserType(): TypeUser {
    return this.dataUser?.idtype_user;
  }

  // Verifica se o usuário tem permissão para um determinado tipo
  hasPermission(requiredType: TypeUser): boolean {
    return this.getUserType() === requiredType;
  }

}
