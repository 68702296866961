import { environment } from './../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { TypeUserServiceService } from 'src/app/components/shared/services/typeUserService.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  #router = inject(Router);
  #http = inject(HttpClient);
  #permissionService = inject(TypeUserServiceService);
  public login(formData): Observable<any> {
    return this.#http
      .post<{ access_token: string; date_user: any }>(
        `${environment.api}auth/login`,
        formData
      )
      .pipe(
        tap((res) => {
          localStorage.removeItem('access_token');
          localStorage.setItem('access_token', res.access_token);
          const token = localStorage.getItem('access_token');

          if (token != undefined && token) {
            this.#router.navigate(['dashboard']);
            window.localStorage.setItem(
              'data_user',
              JSON.stringify(res.date_user)
            );
          } else {
            this.#router.navigate(['login']);
          }

          this.#permissionService.loadUserData();

          return res;
        }),
        catchError((e) => {
          //console.log(e);
          if (e) return throwError(e);

          return throwError(
            () => 'No momento os nossos servidores estão com problemas'
          );
        })
      );
  }

  public logout() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('data_user');
    return this.#router.navigate(['/login']);
  }

  public isAuthenticated(){
    const token = window.localStorage.getItem('access_token');
    return token;
  }
}
