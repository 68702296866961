import { inject, Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { LoginService } from './login.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AcessoInterceptor implements HttpInterceptor {
  #router = inject(Router);
  #loginService = inject(LoginService);

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (request.url.includes('/realstate-storage/')) {
      return next.handle(request);
    }

    const token = localStorage.getItem('access_token');

    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  return next
  .handle(request)
  .pipe(catchError((error: HttpErrorResponse) => this.handleError(error)));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Ocorreu um erro:', error);
    } else {
      console.error(
        `Código do erro ${error.status}, ` +
        `Erro: ${JSON.stringify(error.error)}`);
    }
    if (error.status == 401) {
      this.#router.navigate(['login']);
      localStorage.removeItem('access_token');
      localStorage.removeItem('date_user');

    }
    return throwError(JSON.stringify(error.error.message));
  }
}
